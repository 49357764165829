import React, { ReactElement } from "react";
import Image from "next/image";

export default function ProtectYourCar(): ReactElement {
    return (
        <section className="flex flex-col lg:flex-row  lg:gap-32 gap-8  ">
            <div className="max-w-[600px] mx-auto lg:mx-0 order-2 lg:order-1 lg:mt-12">
                <h2 className="font-bold lg:text-4xl text-3xl lg:leading-[45px] text-[#212F67] ">
                    How to Protect Your Car With an Extended Warranty
                </h2>
                <p className="mt-7 font-light lg:text-lg  text-base lg:leading-8 text-[#3D3D3D]">
                    Your car is a really important and potentially expensive
                    investment. You'll want to ensure it stays in great
                    condition for as long as possible. One way to do that is to
                    protect it by getting an extended auto warranty. But what
                    exactly is an extended car warranty? We will explore
                    everything you need to know about these warranties to help
                    you make an informed decision about whether or not they're
                    right for you. We'll go over the basics, including what
                    vehicle parts and repairs may be covered and how those
                    differ from a manufacturer's warranty coverage. We'll give
                    you some tips on how to choose the right policy, and we'll
                    also discuss potential downsides you may encounter. We hope
                    to help you make a well-rounded decision that works best for
                    your budget and needs.
                </p>
            </div>
            <div className="w-[271px] h-[300px] md:w-[552px] md:min-w-[552px] md:h-[627px]  relative  mx-auto lg:mx-0 order-1 lg:order-2  ">
                <Image
                    src={"/carwarranty/assets/images/protectYourCarImg.png"}
                    alt="Image"
                    layout="fill"
                />
            </div>
        </section>
    );
}

import React, { ReactElement } from "react";
import Image from "next/image";

export default function WhatToResearch(): ReactElement {
    return (
        <section className="lg:mt-[90px] mt-12">
            <h2 className="font-bold lg:text-3xl text-2xl lg:leading-[45px] text-center text-[#212F67]">
                What to Research When Deciding on a VSC
            </h2>

            <p className="mt-5 font-light lg:text-lg  text-base lg:leading-8 mx-auto text-center max-w-[1000px] text-[#3D3D3D]">
                When considering a service contract for your car, making an
                informed decision is crucial. Here are a few important factors
                that can help you make the best decision:
            </p>
            <div className="flex flex-col lg:flex-row  lg:gap-60 gap-8  lg:mt-[113px] mt-[70px]">
                <div className="max-w-[447px] mx-auto lg:mx-0 order-2 lg:order-1">
                    <p className=" font-light lg:text-lg  text-base lg:leading-8 text-[#3D3D3D] mb-4">
                        <span className="font-bold text-[#212F67]">
                            Research Providers:
                        </span>{" "}
                        Start by looking for known, reputable providers online.
                        You'll want companies with a strong track record,
                        positive customer reviews, and industry accreditation,
                        among other things. Sites like BBB and Trustpilot will
                        help you with this task
                    </p>
                    <p className=" font-light lg:text-lg  text-base lg:leading-8 text-[#3D3D3D]">
                        <span className="font-bold text-[#212F67]">
                            Understand Coverage:
                        </span>{" "}
                        Now look at the specific plans offered, carefully read
                        and understand what they cover. Pay attention to the
                        list of components and systems included and any
                        exclusions or limitations.
                    </p>
                </div>
                <div className="w-[271px] h-[230px] md:w-[477px] md:min-w-[477px] md:h-[403px]  relative  mx-auto lg:mx-0 order-1 lg:order-2  ">
                    <Image
                        src={"/carwarranty/assets/images/engine.png"}
                        alt="Image"
                        layout="fill"
                    />
                </div>
            </div>
            <div className="flex flex-col lg:flex-row  lg:gap-32 gap-8  lg:mt-[113px] mt-[70px]">
                <div className="max-w-[447px] mx-auto lg:mx-0 order-2 lg:order-2 lg:mt-14">
                    <p className=" font-light lg:text-lg  text-base lg:leading-8 text-[#3D3D3D] mb-4">
                        <span className="font-bold text-[#212F67]">
                            Check for Flexibility: &nbsp;
                        </span>
                        Look for warranties that allow you to choose a repair
                        facility, whether it's the dealership or an independent
                        mechanic. Flexibility can be key, especially if you have
                        a preferred repair shop
                    </p>
                    <p className=" font-light lg:text-lg  text-base lg:leading-8 text-[#3D3D3D]">
                        <span className="font-bold text-[#212F67]">
                            Compare Costs: &nbsp;
                        </span>
                        Compare quotes from different providers to ensure you're
                        getting a competitive price. Be cautious of warranties
                        that seem too cheap, as they may lack necessary coverage
                    </p>
                </div>
                <div className="w-[271px] h-[190px] md:w-[575px] md:min-w-[575px] md:h-[387px]  relative  mx-auto lg:mx-0 order-1 lg:order-1  lg:-left-12 ">
                    <Image
                        src={"/carwarranty/assets/images/groupOfPp.png"}
                        alt="Image"
                        layout="fill"
                    />
                </div>
            </div>
            <div className="flex flex-col lg:flex-row  lg:gap-40 gap-8  lg:mt-[113px] mt-[70px]">
                <div className="max-w-[447px] mx-auto lg:mx-0 order-2 lg:order-1 lg:mt-8">
                    <p className=" font-light lg:text-lg  text-base lg:leading-8 text-[#3D3D3D] mb-4">
                        <span className="font-bold text-[#212F67]">
                            Compare Costs: &nbsp;
                        </span>
                        Compare quotes from different providers to ensure you're
                        getting a competitive price. Be cautious of warranties
                        that seem too cheap, as they may lack necessary coverage
                    </p>
                    <p className=" font-light lg:text-lg  text-base lg:leading-8 text-[#3D3D3D]">
                        <span className="font-bold text-[#212F67]">
                            Read the Fine Print: &nbsp;
                        </span>
                        When you receive a warranty quote, you should also get a
                        copy of the contract. Carefully read its terms and
                        conditions, paying special attention to the deductible
                        amount, claims procedures, exclusions, and any waiting
                        periods before coverage begins
                    </p>
                </div>
                <div className="w-[271px] h-[200px] md:w-[508px] md:min-w-[508px] md:h-[387px]  relative  mx-auto lg:mx-0 order-1 lg:order-2  ">
                    <Image
                        src={"/carwarranty/assets/images/working.png"}
                        alt="Image"
                        layout="fill"
                    />
                </div>
            </div>
        </section>
    );
}

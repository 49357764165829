import React from "react";

function Comprehensive() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="91"
            height="50"
            fill="none"
            viewBox="0 0 91 50"
        >
            <path
                fill="#009CDB"
                fillRule="evenodd"
                d="M67.165 9.563a48.344 48.344 0 00-25.722-7.456c-3.441.012-6.873.36-10.247 1.04l3.251 6.418 32.718-.002zM49.594 28.41a1.058 1.058 0 011.495 1.496l-5.91 5.91a1.058 1.058 0 01-1.496 0l-3.367-3.365a1.058 1.058 0 011.496-1.495l2.616 2.616 5.166-5.162zm-3.89-11.652a17.38 17.38 0 01-13.138 4.56 315.627 315.627 0 01-.074 4.064c-.246 10.963-.375 16.877 13.207 22.423 13.583-5.546 13.452-11.46 13.207-22.423-.03-1.326-.062-2.719-.074-4.064a17.381 17.381 0 01-13.137-4.56h.009zm-6.841 1.676a15.287 15.287 0 01-7.192.658 1.057 1.057 0 00-1.21 1.046c0 2.022-.035 3.662-.07 5.203l-.022.997H28.51a7.494 7.494 0 00-14.862 0H6.118a3.093 3.093 0 01-1.993-.682l-.773-.602a3.119 3.119 0 01-1.24-2.55V11.908a.23.23 0 01.23-.228h6.414c.179 0 .355-.046.512-.134a78.177 78.177 0 0119.798-7.932l6.59 13.01a1.055 1.055 0 101.884-.951l-2.02-3.99h33.806a1 1 0 00.122-.008 25.622 25.622 0 0117.562 6.118c.335.418.567.909.677 1.432.273.931.427 1.893.457 2.863v4.026a.225.225 0 01-.23.23H77.837a7.493 7.493 0 00-14.863 0H61.04l-.022-.997c-.035-1.54-.072-3.181-.072-5.203a1.057 1.057 0 00-1.263-1.041 15.272 15.272 0 01-13.22-4.553 1.055 1.055 0 00-1.49-.03l-.03.03a15.274 15.274 0 01-6.08 3.884zm22.213 10.019c.111 10.06-1.025 15.887-14.98 21.471-.251.101-.532.101-.784 0-13.956-5.584-15.092-11.41-14.98-21.471h-1.85a7.494 7.494 0 01-14.806 0H6.118a5.158 5.158 0 01-3.286-1.136l-.774-.602A5.21 5.21 0 010 22.503V11.908a2.349 2.349 0 012.345-2.345h6.146C20.029 3.207 31.014.02 41.443 0a51.147 51.147 0 0129.585 9.554 27.77 27.77 0 0117.356 6.63 5.206 5.206 0 011.33 2.44 13.81 13.81 0 01.548 3.458v4.026a2.333 2.333 0 01-.688 1.654 2.33 2.33 0 01-1.653.687H77.808a7.493 7.493 0 01-14.808 0l-1.924.004zm-15.372-5.888a9.55 9.55 0 106.747 2.797 9.518 9.518 0 00-6.751-2.797h.004zm5.256 4.292a7.434 7.434 0 10-10.516 10.51 7.434 7.434 0 0010.516-10.51zm23.247-3.361a5.378 5.378 0 10-7.608 7.604 5.378 5.378 0 007.608-7.605zm-49.325 0a5.376 5.376 0 10-7.601 7.603 5.376 5.376 0 007.6-7.604z"
                clipRule="evenodd"
            ></path>
        </svg>
    );
}

export default Comprehensive;

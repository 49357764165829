import React from "react";

function WrapAroundCoverage() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="66"
            height="50"
            fill="none"
            viewBox="0 0 66 50"
        >
            <path
                fill="#009CDB"
                d="M14.828 0H0v9.9h6.473v6.129h-3.65v17.914h3.65v6.139H0v9.899h14.83v-9.9H8.357v-6.139h7.348c1.918 0 3.479 1.561 3.479 3.48v1.657a2.881 2.881 0 002.878 2.878h21.542a2.881 2.881 0 002.878-2.878v-1.657c0-1.919 1.561-3.48 3.48-3.48h7.68v6.14H51.17v9.899H66v-9.9h-6.473v-6.139h3.316V16.03h-3.316v-6.13H66V0H51.17v9.9h6.473v6.129h-7.681a3.483 3.483 0 01-3.48-3.48v-1.656a2.881 2.881 0 00-2.878-2.879H22.062a2.881 2.881 0 00-2.878 2.879v1.656c0 1.92-1.56 3.48-3.48 3.48H8.358v-6.13h6.473V0h-.002zm-1.885 41.966v6.13H1.883v-6.13h11.06zm51.17 0v6.13h-11.06v-6.13h11.06zM53.053 8.014v-6.13h11.06v6.13h-11.06zM21.067 12.55v-1.656c0-.549.445-.994.993-.994h21.542c.549 0 .994.447.994.994v1.656a5.371 5.371 0 005.364 5.365h10.996v14.144H49.96a5.371 5.371 0 00-5.364 5.364v1.657a.993.993 0 01-.994.993H22.06a.995.995 0 01-.993-.993v-1.657a5.371 5.371 0 00-5.364-5.364H4.706V17.914h10.997a5.371 5.371 0 005.364-5.365zM1.883 8.014v-6.13h11.06v6.13H1.883z"
            ></path>
            <path
                fill="#009CDB"
                d="M8.014 24.986c0 2.73 2.22 4.95 4.95 4.95s4.95-2.22 4.95-4.95-2.22-4.95-4.95-4.95-4.95 2.22-4.95 4.95zm8.015 0a3.067 3.067 0 01-3.065 3.065 3.067 3.067 0 01-3.065-3.065 3.067 3.067 0 013.065-3.065 3.067 3.067 0 013.065 3.065z"
            ></path>
        </svg>
    );
}

export default WrapAroundCoverage;

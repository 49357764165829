import React, { ReactElement } from "react";
import styles from "./style.module.scss";
import Image from "next/image";
export default function RightWarrantyCoverage(): ReactElement {
    return (
        <section className={styles["mainContainer"]}>
            <div className={styles["boxStyle"]}>
                <h2 className="font-bold lg:text-3xl text-2xl lg:leading-[45px]  text-[#212F67]">
                    How to Choose the Right Warranty Coverage
                </h2>
                <p className="lg:text-lg lg:leading-8  leading-7  mt-10  text-[#3D3D3D] mb-4">
                    When selecting an extended warranty company for your car,
                    several factors must be considered. First, take into account
                    the age and mileage of your car. Older cars with higher
                    mileage may be more prone to mechanical breakdowns and may
                    require a more comprehensive warranty. Remember that many
                    providers may not offer their highest plans to cars over a
                    certain mileage limit, but you can find one that does if you
                    shop around.
                </p>
                <p className="lg:text-lg lg:leading-8  leading-7  text-[#3D3D3D] mb-4">
                    Next, evaluate the likelihood of needing major repairs based
                    on the make and model of your car. Some cars have a
                    reputation for being more reliable, while others may have
                    common issues. If your car model may have issues with
                    certain parts, ensure your policy will cover those parts.
                    However, if an issue is part of a recall or is a known
                    manufacturer's defect, you won't be able to claim its repair
                    with a VSC, and you will have to contact your car
                    manufacturer instead.
                </p>
                <p className="lg:text-lg lg:leading-8  leading-7  text-[#3D3D3D]">
                    Also, consider the reputation and financial stability of the
                    service contract provider because you want to ensure that
                    they will be able to honor the contract if needed, and pay
                    special attention to their customer service record.
                    Furthermore, consider the value of their perks and benefits,
                    such as rental car reimbursement or coverage for oil
                    changes. Finally, compare the extended warranty cost to the
                    potential cost of certain repairs. It's important to strike
                    a balance between coverage and affordability.
                </p>
            </div>
            <div className="-mt-7">
                <h2 className="font-bold lg:text-3xl text-2xl lg:leading-[45px]  text-[#212F67]">
                    Decoding the Cost of an Extended Car Warranty
                </h2>
                <p className="lg:text-lg lg:leading-8  leading-7  lg:mt-20 mt-10  text-[#3D3D3D]">
                    If you're considering a policy, it helps to understand the
                    factors that can influence the costs you pay. So many
                    elements come into play with your quote, including the make,
                    model, and age of your vehicle and the length and level of
                    coverage you choose. Although VSC companies might require an
                    upfront payment, they may offer financing or installment
                    options.
                </p>
                <p className="lg:text-lg lg:leading-8  leading-7  text-[#3D3D3D] mb-4">
                    The quotes you get from different providers may vary, but
                    weighing those against the potential savings their plans can
                    provide is important. Keep in mind that, similar to health
                    insurance, extended auto warranties will have some
                    exclusions and rules that might seem strict. Still, they can
                    offer valuable protection against costly repairs down the
                    line. That being said, it's a good idea to consider your
                    specific needs and budget before making a decision.
                </p>
                <p className="lg:text-lg lg:leading-8  leading-7  text-[#3D3D3D]">
                    To ensure you're getting the best value for your money, it's
                    important to compare different warranty providers and
                    carefully read their contracts, including the fine print.
                    This will help you understand exactly what's covered and
                    what isn't. While their costs may seem high, they can be
                    worth it in the long run—especially when you factor in the
                    potential cost of repairs you may face.
                </p>
            </div>
            <div className="flex flex-col lg:flex-row  lg:gap-32 gap-8 lg:mt-[95px] mt-10 ">
                <div className="max-w-[500px] mx-auto lg:mx-0 order-2 lg:order-1">
                    <h2 className="font-bold lg:text-3xl text-2xl lg:leading-[45px]  text-[#212F67]">
                        Making the Best Coverage Decision for Yourself
                    </h2>
                    <p className="mt-14 font-light lg:text-lg  text-base lg:leading-8 text-[#3D3D3D]">
                        Extended car warranties can provide peace of mind and
                        financial security while helping you protect your
                        vehicle. Whether you choose a manufacturer's extended
                        plan or one from a third-party provider, it's important
                        to carefully consider the coverage options, terms, and
                        costs involved. You should take into account factors
                        such as the age and mileage of your car, as well as your
                        budget and individual needs, when looking for
                        appropriate coverage for your car.
                    </p>
                </div>
                <div className="w-[271px] h-[243px] md:w-[505px] md:min-w-[505px] md:h-[480px]  relative  mx-auto lg:mx-0 order-1 lg:order-2 ">
                    <Image
                        src={"/carwarranty/assets/images/peace.png"}
                        alt="Image"
                        layout="fill"
                    />
                </div>
            </div>
            <div className="flex flex-col lg:flex-row  lg:gap-40 gap-8 lg:mt-[95px] mt-10 lg:mb-[210px] mb-[100px]">
                <div className="max-w-[500px] mx-auto lg:mx-0 order-2 lg:mt-16">
                    <p className=" font-light lg:text-lg  text-base lg:leading-8 text-[#3D3D3D]">
                        Of course, while it can save you from unexpected repair
                        expenses, some plans may not be necessary or
                        cost-effective for your specific requirements. Conduct
                        thorough research, read customer reviews, and consult
                        with experts to make an informed decision. You should
                        take your time, thoroughly weigh the pros and cons of
                        any warranty provider you consider, and make an informed
                        purchase decision about what type of coverage and which
                        provider is right for you. We think that when you
                        consider the potential savings and benefits an extended
                        vehicle warranty contract offers, you will see a lot of
                        value in getting one for your car. The right choice is
                        out there for you!
                    </p>
                </div>
                <div className="w-[271px] h-[293px] md:w-[433px] md:min-w-[433px] md:h-[488px]  relative  mx-auto lg:mx-0 order-1 lg:ml-8 ">
                    <Image
                        src={"/carwarranty/assets/images/goodDeal.png"}
                        alt="Image"
                        layout="fill"
                    />
                </div>
            </div>
        </section>
    );
}

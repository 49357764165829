import React, { ReactElement } from "react";
import styles from "./style.module.scss";
import FeatureList from "@components/shared/featureList";
import Powertrain from "./icons/powertrain";
import Comprehensive from "./icons/comprehensive";
import Exclusionary from "./icons/exclusionary";
import WrapAroundCoverage from "./icons/wrapAroundCoverage";
import SpecializedCoverage from "./icons/specializedCoverage";
const FinancialResilienceList = [
    {
        description: (
            <>
                <span className="font-bold">Powertrain:</span>{" "}
                <span>
                    This is usually your basic warranty coverage that covers the
                    engine and drivetrain parts like the transmission, drive
                    axles, and transfer unit. This is the lowest layer of
                    protection that comes with your manufacturer's warranty and
                    the last to expire
                </span>
            </>
        ),
        icon: <Powertrain />,
    },
    {
        description: (
            <>
                <span className="font-bold">Comprehensive:</span>{" "}
                <span>
                    These plans typically cover the highest number of stated
                    components but may also have a lot of exclusions. They might
                    cover your fuel system, cooling system, suspension,
                    anti-lock brakes, and high-tech electronics, among many
                    possible categories
                </span>
            </>
        ),
        icon: <Comprehensive />,
    },
    {
        description: (
            <>
                <span className="font-bold">Exclusionary:</span>{" "}
                <span>
                    This type of plan is the highest tier of coverage and is
                    meant to cover all breakdown repairs except those that the
                    contract explicitly excludes. This is the closest thing to
                    your manufacturer's bumper-to-bumper warranty, which
                    brand-new cars come with for a limited time
                </span>
            </>
        ),
        icon: <Exclusionary />,
    },
    {
        description: (
            <>
                <span className="font-bold">Wrap-around coverage:</span>{" "}
                <span>
                    Providers might offer comprehensive plans that cover
                    everything but the powertrain components, designed for newer
                    cars with still valid powertrain factory warranties
                </span>
            </>
        ),
        icon: <WrapAroundCoverage />,
    },
    {
        description: (
            <>
                <span className="font-bold">Specialized coverage:</span>{" "}
                <span>
                    Some providers will offer special plans for hybrid or
                    electric cars or for different vehicle types like
                    motorcycles or 4x4s. You might also find these vehicle types
                    or parts covered as add-on packages, as you might for luxury
                    electronics, emissions, or commercial-use cars
                </span>
            </>
        ),
        icon: <SpecializedCoverage />,
    },
];

export default function CoveredUnderAnExtended(): ReactElement {
    return (
        <section className={styles["mainContainer"]}>
            <div className="lg:pt-[90px] pb-[50px] pt-10 ">
                <FeatureList
                    featureList={FinancialResilienceList}
                    mainTitle="What's covered under an extended car warranty or VSC?"
                    mainDescription={
                        "Extended car warranties provide coverage for various parts and components of your vehicle. Plans can differ from each other in the extent to which they cover those important parts. You can come across many types or tiers of plans, some of which are:"
                    }
                    colors={{
                        mainTitleColor: "#212F67",
                        mainDescriptionColor: "#3D3D3D",
                        descriptionColor: "#3D3D3D",
                    }}
                    classes={{
                        mainSectionClasses: "  ",
                        mainTitleClasses:
                            " text-2xl  lg:text-3xl font-bold text-center sm:text-left",
                        mainDescriptionClasses:
                            "mt-[20px] lg:text-lg lg:leading-8 text-center sm:text-left leading-7 ",
                        innerSectionClasses:
                            "flex   flex-wrap   sm:gap-[88px] gap-[60px] mt-[72px] justify-center",
                        oneBoxClasses: `${styles["oneBoxStyle"]}   `,
                        iconClasses: "children:mx-auto sm:children:mx-0 ",
                        descriptionClasses: `font-light text-center sm:text-left text-[#3D3D3D] leading-7`,
                    }}
                />
                <p className="lg:text-lg lg:leading-8 text-center sm:text-left leading-7  mt-[70px]  text-[#3D3D3D]">
                    Some of the above parts categories might be called by
                    different names or divided into smaller component categories
                    depending on the provider, and the number of components
                    included will also differ. Most extended warranties will
                    also offer additional benefits, which may include roadside
                    assistance, towing, rental car or alternative transportation
                    reimbursements, and paying for your expenses if you break
                    down far away from home.
                </p>
                <p className="lg:text-lg lg:leading-8 text-center sm:text-left leading-7  text-[#3D3D3D]">
                    On the other hand, while regular vehicle maintenance is
                    crucial to maintaining your coverage, most warranty plans
                    will not cover it. You have to make the effort to keep up
                    with recommended service intervals, change fluids, replace
                    worn-out parts, and promptly address minor issues. Most
                    extended warranties require proof of proper maintenance to
                    honor claims.
                </p>
            </div>
        </section>
    );
}

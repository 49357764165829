import React from "react";

function SpecializedCoverage() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="51"
            height="50"
            fill="none"
            viewBox="0 0 51 50"
        >
            <path
                fill="#009CDB"
                d="M20.805 41.608c3.176 0 6.176-.736 8.871-2.015l8.937 8.937a5.032 5.032 0 007.11-.002l2.804-2.813a5.007 5.007 0 00.002-7.1l-8.933-8.943a20.642 20.642 0 002.013-8.868C41.609 9.333 32.276 0 20.804 0 9.334 0 0 9.333 0 20.804c0 11.472 9.333 20.804 20.805 20.804zm26.253-1.518a2.925 2.925 0 01-.003 4.15l-2.806 2.817c-1.115 1.114-3.048 1.114-4.163 0L31.59 38.56a20.928 20.928 0 006.974-6.975l8.494 8.505zM20.805 2.083c10.322 0 18.72 8.399 18.72 18.721 0 10.322-8.398 18.72-18.72 18.72-10.323 0-18.721-8.398-18.721-18.72s8.398-18.72 18.72-18.72z"
            ></path>
            <path
                fill="#009CDB"
                d="M20.79 21.235c3.144 0 5.703-3.201 5.703-7.136 0-3.672-2.239-6.045-5.704-6.045s-5.704 2.373-5.704 6.045c0 3.935 2.559 7.136 5.704 7.136zm0-11.098c2.266 0 3.619 1.48 3.619 3.962 0 2.786-1.624 5.053-3.62 5.053s-3.62-2.267-3.62-5.053c0-2.481 1.353-3.962 3.62-3.962zM10.523 33.554h20.564c1.152 0 2.09-.937 2.09-2.09v-3.157c0-1.37-.813-2.607-2.024-3.128-6.757-3.326-13.782-3.31-20.65-.022a3.427 3.427 0 00-2.071 3.15v3.156c0 1.154.938 2.091 2.091 2.091zm-.008-5.247c0-.538.32-1.024.85-1.254 3.187-1.525 6.386-2.298 9.51-2.298 3.118 0 6.267.771 9.405 2.315.494.213.814.698.814 1.237l-.005 3.164h-.002l-20.572-.008v-3.156z"
            ></path>
        </svg>
    );
}

export default SpecializedCoverage;

import React, { ReactElement } from "react";
import { GetServerSideProps } from "next";
import { getPageProps } from "@services/initial-calls";
import CarWarrantyWrapper from "@components/layouts/carWarrantyWrapper";
import ProtectYourCar from "@components/carwarranty/protectYourCar";
import ExtendedCarWarranty from "@components/carwarranty/extendedCarWarranty";
import CoveredUnderAnExtended from "@components/carwarranty/coveredUnderAnExtended";
import WhatToResearch from "@components/carwarranty/whatToResearch";
import RightWarrantyCoverage from "@components/carwarranty/rightWarrantyCoverage";
export default function CarWarrantyHome(): ReactElement {
    return (
        <CarWarrantyWrapper>
            <ProtectYourCar />
            <ExtendedCarWarranty />
            <CoveredUnderAnExtended />
            <WhatToResearch />
            <RightWarrantyCoverage />
        </CarWarrantyWrapper>
    );
}

export const getServerSideProps: GetServerSideProps = async (context) => {
    return await getPageProps(context, "home", { isStaticContent: true });
};

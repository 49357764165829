import React from "react";

function Powertrain() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="70"
            height="50"
            fill="none"
            viewBox="0 0 70 50"
        >
            <path
                fill="#009CDB"
                d="M67.857 44.048h-4.762a1.19 1.19 0 01-1.19-1.19v-4.763a1.19 1.19 0 112.38 0v3.572h2.382V29.762h-2.381v3.571a1.19 1.19 0 01-2.381 0v-4.761a1.19 1.19 0 011.19-1.191h4.762a1.19 1.19 0 011.19 1.19v14.286a1.19 1.19 0 01-1.19 1.19z"
            ></path>
            <path
                fill="#009CDB"
                d="M63.095 34.524h-5.952a1.19 1.19 0 110-2.381h5.952a1.19 1.19 0 010 2.38zM63.095 39.286h-5.952a1.19 1.19 0 010-2.381h5.952a1.19 1.19 0 110 2.38zM11.905 34.524H5.952a1.19 1.19 0 110-2.381h5.953a1.19 1.19 0 010 2.38zM11.905 39.286H5.952a1.19 1.19 0 010-2.381h5.953a1.19 1.19 0 110 2.38zM41.667 7.143h-4.762a1.19 1.19 0 010-2.381h3.571V2.38H28.571v2.38h3.572a1.19 1.19 0 010 2.382H27.38a1.19 1.19 0 01-1.19-1.19V1.19A1.19 1.19 0 0127.38 0h14.286a1.19 1.19 0 011.19 1.19v4.762a1.19 1.19 0 01-1.19 1.19z"
            ></path>
            <path
                fill="#009CDB"
                d="M32.143 13.095a1.19 1.19 0 01-1.19-1.19V5.952a1.19 1.19 0 112.38 0v5.953a1.19 1.19 0 01-1.19 1.19zM36.905 13.095a1.19 1.19 0 01-1.19-1.19V5.952a1.19 1.19 0 012.38 0v5.953a1.19 1.19 0 01-1.19 1.19zM5.952 44.048H1.19A1.19 1.19 0 010 42.858V28.571a1.19 1.19 0 011.19-1.191h4.762a1.19 1.19 0 011.19 1.19v4.762a1.19 1.19 0 01-2.38 0v-3.571H2.38v11.905h2.38v-3.572a1.19 1.19 0 112.382 0v4.762a1.19 1.19 0 01-1.19 1.19z"
            ></path>
            <path
                fill="#009CDB"
                d="M11.905 34.524a1.19 1.19 0 01-1.19-1.19V20.237a1.19 1.19 0 011.19-1.19h6.535l5.596-7.834a1.191 1.191 0 01.964-.5h7.143a1.19 1.19 0 010 2.381h-6.536l-5.595 7.833a1.19 1.19 0 01-.964.5h-5.953v11.905a1.19 1.19 0 01-1.19 1.19zM57.143 34.524a1.19 1.19 0 01-1.19-1.19V21.427H52.38a1.191 1.191 0 01-.964-.5l-5.596-7.833h-8.916a1.19 1.19 0 010-2.38h9.523a1.191 1.191 0 01.965.5l5.595 7.832h4.155a1.19 1.19 0 011.19 1.19v13.096a1.19 1.19 0 01-1.19 1.19zM53.571 50h-23.81a1.192 1.192 0 01-.844-.345l-3.215-3.226H11.905a1.19 1.19 0 01-1.19-1.19v-7.144a1.19 1.19 0 012.38 0v5.953H26.19a1.19 1.19 0 01.846.345l3.214 3.226h22.833l2.87-2.869v-6.655a1.19 1.19 0 112.38 0v7.143a1.192 1.192 0 01-.345.846l-3.571 3.57a1.192 1.192 0 01-.846.346z"
            ></path>
            <path
                fill="#009CDB"
                d="M32.143 39.286a1.19 1.19 0 01-.917-1.952l4.334-5.19h-4.608a1.19 1.19 0 01-.916-1.953l5.952-7.143a1.192 1.192 0 011.834 1.524l-4.334 5.19h4.607a1.191 1.191 0 01.917 1.953l-5.952 7.142a1.19 1.19 0 01-.917.429z"
            ></path>
        </svg>
    );
}

export default Powertrain;

import React, { ReactElement } from "react";

export default function ExtendedCarWarranty(): ReactElement {
    return (
        <section className="lg:mt-[114px] mt-16 ">
            <h2 className="font-bold lg:text-3xl text-2xl lg:leading-[45px] text-[#212F67] ">
                What Are Extended Car Warranties?
            </h2>

            <p className="mt-12 font-light lg:text-lg  text-base lg:leading-8 text-[#3D3D3D]">
                An auto warranty will protect your vehicle against repairs to a
                variable amount of parts under its hood. They are designed to
                protect you from unexpected repair costs due to breakdowns of
                your car's mechanical or electrical parts, helping to bring you
                some peace on the road. A new vehicle will come off the lot
                already covered by a manufacturer's warranty, sometimes called a
                factory warranty. This warranty typically has two layers of
                coverage that would cover it for a certain amount of time or
                driving miles. This means your car's warranty starts with a
                "bumper-to-bumper" protection layer, and when that expires, you
                are left with a much more limited powertrain warranty for the
                remainder of its term.
            </p>
            <p className="font-light lg:text-lg  text-base lg:leading-8 text-[#3D3D3D] mb-8">
                Extended car warranties go beyond your car's original factory
                warranty term to provide additional coverage for your vehicle.
                What you are extending there is the amount of parts you're
                covered for and the amount of time or miles that the coverage
                lasts. This type of coverage may be known as a vehicle service
                contract (VSC) when it's sold by third-party providers that are
                independent of your vehicle's manufacturer, and in California,
                it might be sold as Mechanical Breakdown Insurance (MBI)
                policies. VSCs are a great idea whether you have a newer car
                with an expiring factory warranty or a well-used older car with
                many more miles on it.
            </p>
            <p className="font-light lg:text-lg  text-base lg:leading-8 text-[#3D3D3D]">
                When considering one of these vehicle protection plans, be sure
                to review the exclusions and limitations and compare options
                from different providers. Really, the only way to ensure you
                know how much you are covered is to read the contract's fine
                print, which includes looking for any mileage limits or
                restrictions that may apply. This can be true whether you buy
                your warranty protection from a dealership or a warranty company
                over the phone because you may not think of everything you want
                to ask on the spot or have enough time to ask it. By
                familiarizing yourself with the contract details, you can get
                the most out of your warranty and ensure that it meets your
                specific needs.
            </p>
            <h2 className="font-bold lg:text-3xl text-2xl lg:leading-[45px] lg:mt-24 mt-10 text-[#212F67]">
                The Basics of Extended Car Warranties
            </h2>

            <p className="mt-5 font-light lg:text-lg  text-base lg:leading-8 text-[#3D3D3D]">
                Extended car warranties act like insurance for your car's major
                components. Of course, unlike your car insurance, your warranty
                will not cover accidental damage, among other exclusions.
                Generally, they cover repairs for specific mechanical breakdowns
                and come with a deductible, which is the amount the owner must
                pay out of pocket for each qualified repair. In addition to
                covering repairs, many car warranties also include benefits like
                roadside assistance for added convenience. However, it's
                important to note that they usually do not cover routine
                maintenance. As a car owner, you can purchase an extended
                warranty upfront or add it later, depending on your preferences
                and needs. If you take the time to understand what different
                types of service contracts can do for you, you'll be more
                empowered to decide what option to choose and when to protect
                your vehicle.
            </p>
        </section>
    );
}
